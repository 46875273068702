import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_crm_list = _resolveComponent("crm-list")!

  return (_openBlock(), _createBlock(_component_crm_list, {
    url: "/malicrm/customer/customerList",
    filterOption: _ctx.filterOptions,
    columOptions: _ctx.columnList,
    customParams: { listType: 3 },
    ref: "tableRef",
    id: "recycleClientList",
    onRowClick: _ctx.rowClick
  }, {
    header: _withCtx(() => [
      _createElementVNode("button", {
        class: "erp-button primary",
        onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.back && _ctx.back(...args)))
      }, "返回"),
      _createElementVNode("button", {
        class: "erp-button primary",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.refreshTable(false)))
      }, "刷新")
    ]),
    _: 1
  }, 8, ["filterOption", "columOptions", "onRowClick"]))
}