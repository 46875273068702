
import { defineComponent } from 'vue';
import CrmList from '@/components/CrmList/CrmList.vue';
import useListData from './components/useListData';
import { loadPage } from '@/utils/publicMethods';
import router from '@/router';

export default defineComponent({
  name: 'RecycleClientList',
  components: { CrmList },
  props: {},
  setup() {
    const { filterOptions, columnList, tableRef } = useListData(3);
    const back = () => {
      router.push('/client/clientList');
    };
    const refreshTable = () => {
      tableRef.value.refreshTable(false);
    };
    const rowClick = (row) => {
      loadPage('ContactDetails', {
        id: row.id,
        type: 3, // 1客户 2客户池 3回收站
      });
    };

    return {
      filterOptions,
      columnList,
      tableRef,
      refreshTable,
      back,
      rowClick,
    };
  },
});
